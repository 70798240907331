<template>
    <div>
        <search-bar :objects="available" @filter="onFilter"
                    :fuse-options="fuseOptions" class="float-left col-md-6 pl-0"/>
        <div class="float-right">
            <b-pagination :total-rows="totalItems" v-model="currentPage" :per-page="perPage"/>
        </div>
        <b-table borderless thead-class="d-none" outlined class="lh-64"
                 :items="filteredAvailable"
                 :fields="['selected', 'imageExtension', 'nom', 'actions']"
                 :responsive="true"
                 ref="bTable"
                 :per-page="perPage"
                 :sort-compare="bTableSortCompare"
                 :current-page="currentPage">
            <template #cell(selected)="{ item }">
                <div class="fs-40 w-100 text-right">
                    <font-awesome-icon v-if="isItemSelected(item)" :icon="['far', 'check-circle']"/>
                </div>
            </template>
            <template #cell(imageExtension)="{ item }">
                <img :src="thumbnailSrc(item, type)" :alt="'Image du ' + type.substring(0, -1)"
                     class="thumbnail-object-image"/>
            </template>
            <template #cell(nom)="{ value }">
                <div class="borrowable-nom w-100 text-left">{{ value }}</div>
            </template>
            <template #cell(actions)="{ item }">
                <b-button :variant="isItemSelected(item) ? 'outline-danger' : 'outline-success'"
                          @click="toggleSelection(item)" pill v-if="item._selectable"
                          v-b-tooltip.hover="isItemSelected(item) ? 'Retirer' : 'Ajouter'">
                    <font-awesome-icon :icon="isItemSelected(item) ? 'minus' : 'plus'"/>
                </b-button>
                <b-button variant="outline-primary" @click="showDetails(item)" pill
                          v-b-tooltip.hover="'Afficher le détail'">
                    <font-awesome-icon icon="eye"/>
                </b-button>
                <b-button variant="outline-info" target="_blank" pill
                          :to="{name: 'dashboard_calendar', query: {[type.slice(0, -1)] : item.id}}"
                          v-b-tooltip.hover="'Afficher dans le calendrier (nouvel onglet)'">
                    <font-awesome-icon :icon="['far', 'calendar-alt']"/>
                </b-button>
            </template>
        </b-table>
    </div>
</template>

<script>
    import {thumbnailSrc}          from '@/util/image';
    import {loadAndDisplayDetails} from '@/util/borrowable';
    import {bTableSortCompare}     from '@/util/sort';

    const SearchBar = () => import('@/components/SearchBar');

    export default {
        name: "SelectEmpruntableTable",
        components: {SearchBar},
        props: {
            value: {
                type: Array,
                default: []
            },
            available: {
                type: Array,
                required: true
            },
            type: {
                type: String,
                required: true
            }
        },
        data: () => ({
            selectedRows: [],
            filteredAvailable: [],
            currentPage: 1,
            selectableRows: [],
            perPage: 10,
            fuseOptions: {
                keys: [
                    {
                        name: 'nom',
                        weight: 0.6
                    }, {
                        name: 'description',
                        weight: 0.4
                    }
                ]
            }
        }),
        computed: {
            totalItems() {
                return this.filteredAvailable.length;
            }
        },
        methods: {
            thumbnailSrc,
            bTableSortCompare,
            isItemSelected(item) {
                return this.selectedRows.map(({id}) => id).includes(item.id);
            },
            toggleSelection(item) {
                if (this.isItemSelected(item) || !item._selectable)
                    this.selectedRows = this.selectedRows.filter(({id}) => id !== item.id);
                else
                    this.selectedRows.push({...item});

                this.available.forEach((item, key, array) =>
                    this.$set(
                        array[key],
                        '_rowVariant',
                        this.isItemSelected(item) ? 'success' : item._selectable ? null : 'light'
                    )
                );

                this.$emit('input', this.selectedRows);
            },
            /**
             * Used from parent element after available items have been fetched
             * @param items
             */
            setSelectables(items) {
                this.selectableRows = items;
                this.available.forEach((itemAvailable, key, available) => {
                    this.$set(available[key], '_rowVariant', null);
                    let isSelectable = items.includes(itemAvailable.id);
                    this.$set(available[key], '_selectable', isSelectable);
                    if (!isSelectable) {
                        this.$set(available[key], '_rowVariant', 'light');
                        this.selectedRows = this.selectedRows.filter(item => item.id !== itemAvailable.id);
                    } else if (this.isItemSelected(itemAvailable)) {
                        this.$set(available[key], '_rowVariant', 'success');
                    }
                });
            },
            /**
             * Wrapper for the borrowable.js util to handle errors
             * @param item
             */
            showDetails(item) {
                loadAndDisplayDetails(item, this.type)
                    .catch(() => this.$toaster.error('Impossible de charger le détail'));
            },
            onFilter(val) {
                this.filteredAvailable = val;
            }
        },
        mounted() {
            this.filteredAvailable = this.available;
        }
    }
</script>

<style scoped>
    .thumbnail-object-image {
        max-width: 64px;
        max-height: 64px;
    }

    .borrowable-nom {
        font-size: 1.25rem;
    }
</style>